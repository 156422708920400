import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import appsettings from 'appsettings.json';
import { GeneralSettingsService } from 'app/general-settings/services/general-settings.service';
import { AuthService as Service } from 'common/helpers/auth/auth.service';
import { TopbarService } from 'common/layout/components/header/topbar/services/Topbar.service';
import { useSignalR } from 'common/helpers/signalR/context/signalR.hook';
import AppLogo from 'common/partials/AppLogo';
import ItLabLogo from 'assets/images/logos/itlab-logo.png';
import { AuthModel as Model } from '../model/login.model';
import ForgottenPasswordModal from 'app/recovery-password/components/ForgotenPasswordModal';

interface LoginProps {
  onSucessfulLogin(): any;
}
export function Login({ onSucessfulLogin }: LoginProps) {
  const [loading, setLoading] = useState(false);
  const [openForgottenPassword, setOpenForgottenPassword] = useState(false);
  const { t } = useTranslation();
  const { closeHubConnection } = useSignalR();

  const addMinutes = (date: Date, minutes: number) => {
    return new Date(date.getTime() + minutes * 60000);
  }

  const fetchPresets = async () => {
    const response = await GeneralSettingsService.getPresets();
    if (response && !response.hasErrors) {
      const dataAsString = JSON.stringify(response.data);
      sessionStorage.setItem('appPresets', dataAsString);
    }
  }

  const logIn = async (values: Model) => {
    setLoading(true);
    try {
      const response = await Service.authenticate(values.username, values.password);
      if (response && !response.hasErrors) {
        Service.login(response.data);
        toast.success(`${t('login.success')}`);
        fetchPresets();
        setLoading(false);
        onSucessfulLogin();
      } else {
        const errors = response?.errorCode;
        toast.error(`${t(`error.${errors}`)}`);
        setLoading(false);
      }
    } catch (err) {
      toast.error('Username or password is incorrect.');
      setLoading(false);
    }
  };

  const formSchema = Yup.object().shape({
    username: Yup.string().required('Username is required.'),
    password: Yup.string().required('Password is required.'),
  });

  const formik = useFormik<Model>({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: formSchema,
    onSubmit: logIn,
  });

  useEffect(() => {
    closeHubConnection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <form className="form w-100" onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">
        <div className="text-center mb-10">
          <AppLogo height={70} />
        </div>
        <h3 className="text-center mb-3">{t("login.title")}</h3>
        <div className="text-end mb-5">
          <h1 className="text-center mb-3">{appsettings['application-name']}</h1>
          <span className="text-muted text-end fw-bolder fs-8">
            <span>{t('topbar.system.version')}</span>
            {appsettings['application-version']}
          </span>
        </div>

        <div className="mb-8">
          <input
            placeholder={t("login.form.username.label")}
            {...formik.getFieldProps('username')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.username && formik.errors.username },
              {
                'is-valid': formik.touched.username && !formik.errors.username,
              },
            )}
            type="text"
            name="username"
            autoComplete="off"
          />
          {formik.touched.username && formik.errors.username && (
            <div className="mt-3 text-danger fw-bold">
              <span role="alert">{t("crud.validators.requiredField")}</span>
            </div>
          )}
        </div>

        <div className="mb-8">
          <input
            type="password"
            placeholder={t("login.form.password.label")}
            autoComplete="off"
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              },
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className="mt-3 text-danger fw-bold">
              <div className="fv-help-block">
                <span role="alert">{t("crud.validators.requiredField")}</span>
              </div>
            </div>
          )}
        </div>

        <div className="text-center">
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn btn-lg btn-primary w-100 mb-5"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className="indicator-label">{t("generalMessages.signin")}</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: 'block' }}>
                {t("crud.common.wait")}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </form>

      <div className="text-center">
        <button type='button' className='btn btn-link' onClick={() => setOpenForgottenPassword(true)}>Esqueci minha senha</button>
      </div>

      <div className="d-flex align-items-center justify-content-center gap-3">
        <span>{t("login.footer")}</span>
        <img alt="Logo" height={25} src={ItLabLogo} />
      </div>

      <ForgottenPasswordModal show={openForgottenPassword} onHide={() => setOpenForgottenPassword(false)} />
    </>
  );
}
