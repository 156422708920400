import { http } from 'common/helpers/axios/axios-helper';
import { ChangePassword, UserProfileModel, UserProfileLanguage } from '../model/user-profile.model';
import {
  TenantManagementModel,
  TenantManagementModel as Model
} from "../../tenant-manegement/model/tenant-management.model";

const basePath = '/user';

async function putUserLanguage(data: UserProfileLanguage) {
  return await http.put(`${basePath}/language`, data);
}

async function putUser(user: UserProfileModel) {
  return await http.put<UserProfileModel>(`${basePath}/${user.id}`, user);
}

async function changePasswordUser(user: ChangePassword) {
  return await http.post<ChangePassword>(`${basePath}/changepassword`, user);
}

async function getUserAvatar() {
  return await http.get(`me/avatar`);
}

async function postUserAvatar(body: any) {
  return await http.post(`me/avatar`, body, { headers: { 'Content-Type': 'multipart/form-data' } });
}

async function getUserById(userId: any) {
  return await http.get<UserProfileModel>(`${basePath}/${userId}`);
}

async function getUserTenants(userId: any) {
  return await http.get<TenantManagementModel[]>(`${basePath}/${userId}/tenants`);
}



export const UserProfileService = {
  putUserLanguage,
  changePasswordUser,
  putUser,
  getUserById,
  getUserAvatar,
  postUserAvatar,
  getUserTenants,
};
