import i18next from 'i18next';
import DataTable, { TableColumn } from 'react-data-table-component';
import { toast } from 'react-toastify';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { tableHelper } from 'common/helpers/table/table-helper';
import { RolesDictionary } from 'common/helpers/dictionaries/roles-dictionary';
import { itLabTableStyle } from 'common/helpers/table/table-style';
import { DeleteModal } from 'common/partials/DeleteModal';
import ActionButton from 'common/partials/ActionButton';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import es from '../i18n/es-es.json';
import { ProcessStepsModel as Model } from '../model/process-steps.model';
import { ProcessStepsService as Service } from '../services/process-steps.service';
import { useParams } from 'react-router-dom';

interface Props {
	handleOpen: (id: number) => void;
	isFetching: boolean;
}

export function ProcessSteps(props: Props) {
	i18next.addResourceBundle('us', 'translation', en);
	i18next.addResourceBundle('br', 'translation', pt);
	i18next.addResourceBundle('es', 'translation', es);

	const { t } = useTranslation();
	const { id } = useParams();
	const [tableData, setTableData] = useState<Model[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedRows, setSelectedRows] = useState<Model[]>([]);
	const [toggleCleared, setToggleCleared] = useState(false);
	const [deleteId, setDeleteId] = useState(0);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const handleShowDeleteModal = (id: number) => {
		setShowDeleteModal(true);
		setDeleteId(id);
	};

	const handleCloseDeleteModal = () => setShowDeleteModal(false);

	const toMMSS = (numSecs: any) => {
		let secNum: any = parseInt(numSecs, 10);
		let hours: any = Math.floor(secNum / 3600)
			.toString()
			.padStart(2, '0');
		let minutes: any = Math.floor((secNum - (hours * 3600)) / 60)
			.toString()
			.padStart(2, '0');
		let seconds: any = (secNum - (hours * 3600) - (minutes * 60))
			.toString()
			.padStart(2, '0');

		return `${minutes}:${seconds}`;
	};

	const fetchData = async () => {
		try {
			setIsLoading(true);
			const response = await Service.getDataByProcessId(id);
			if (response && !response.hasErrors) {
				setTableData(response.data);
			}
		} catch (err) {
			toast.error(`${t('crud.read.errorMessage')}`);
		} finally {
			setIsLoading(false);
		}
	};

	const columns: TableColumn<Model>[] = [
		{
			name: `${t('table.id')}`,
			selector: (row) => row.id || '',
			sortable: true,
			width: '60px',
		},
		{
			name: `${t('table.name')}`,
			selector: (row) => row.name || '',
			sortable: true,
		},
		{
			name: `${t('table.averageTime')}`,
			selector: (row) => toMMSS(row.averageTimeInMinutes) || '',
			sortable: true,
		},
		{
			name: `${t('table.lastExecution')}`,
			selector: (row) => (row.lastExecution !== '' ? moment(row.lastExecution).format('L') : '-'),
			sortable: true,
		},
		{
			name: `${t('table.nextExecution')}`,
			selector: (row) => (row.nextExecution !== '' ? moment(row.nextExecution).format('L') : '-'),
			sortable: true,
		},
		{
			name: `${t('table.status')}`,
			cell: (row) =>
				row.isActive ? (
					<span className="badge badge-light-primary">{t('generalMessages.active')}</span>
				) : (
					<span className="badge badge-light-danger">{t('generalMessages.inactive')}</span>
				),
			center: true,
		},
		{
			name: `${t('table.actions')}`,
			cell: (row) =>
				row.id && (
					<div className="d-grid d-flex gap-2">
						<ActionButton type='edit' onClick={() => props.handleOpen(row.id!)} roles={[RolesDictionary.Process.Processes_Update]} />
						<ActionButton type='deactivate' onClick={() => handleShowDeleteModal(row.id!)} roles={[RolesDictionary.Process.Processes_Update]} disabled={!row.isActive} />
					</div>
				),
			center: true,
			width: '100px'
		},
	];

	const handleRowSelected = useCallback((state: any) => {
		setSelectedRows(state.selectedRows);
	}, []);

	const deleteDataById = async () => {
		try {
			setIsLoading(true);
			const result = await Service.deleteDataById(deleteId);
			if (result && !result.hasErrors) {
				toast.success(`${t('crud.deactivate.successMessage')}`);
			} else {
				toast.error(`${t('crud.deactivate.errorMessage')}`);
			}
		} catch (err) {
			toast.error(`${t('crud.read.errorMessage')}`);
		} finally {
			setToggleCleared(!toggleCleared);
			handleCloseDeleteModal();
			fetchData();
			setIsLoading(false);
		}
	};

	const contextActions = useMemo(() => {
		const deleteSelectedRows = () => {
			setShowDeleteModal(true);
			selectedRows.forEach((row) => {
				setDeleteId(row.id!);
			});
		}

		return (
			<button type="button" className="btn btn-sm btn-danger" onClick={deleteSelectedRows}>
				{t('crud.deactivate.buttonSave')}
			</button>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedRows, toggleCleared]);

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.isFetching]);

	return (
		<div className="card card-flush shadow-none mt-10">
			<div className="card-body p-0">
				<DataTable
					title={selectedRows.length === 0 ? false : <></>}
					persistTableHead={true}
					columns={columns}
					data={tableData}
					noDataComponent={tableHelper.getNoDataComponent()}
					selectableRows
					pagination
					paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
					highlightOnHover
					pointerOnHover
					progressPending={isLoading}
					progressComponent={tableHelper.getLoader()}
					contextActions={contextActions}
					contextMessage={tableHelper.getContextMessage()}
					onSelectedRowsChange={handleRowSelected}
					clearSelectedRows={toggleCleared}
					selectableRowsVisibleOnly={true}
					customStyles={itLabTableStyle}
					dense
				/>
			</div>

			<DeleteModal
				type='deactivate'
				handleDelete={deleteDataById}
				onClose={handleCloseDeleteModal}
				open={showDeleteModal}
			/>
		</div>
	);
}
