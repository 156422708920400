import { http } from 'common/helpers/axios/axios-helper';
import { TenantAndUsersModel } from '../model/user-selector.model';

const basePath = '/tenant';

async function getTenantAndUsers(tenantId: any) {
  return await http.get<TenantAndUsersModel>(`${basePath}/${tenantId}/allusers`);
}

async function putUsers(tenantId: any, users: number[]) {
  let data = { tenantId: tenantId, userIds: users };
  return await http.put(`${basePath}/updateUserTenant`, data);
}

export const UserSelectorService = { getTenantAndUsers, putUsers };
