import { http } from 'common/helpers/axios/axios-helper';
import { TenantManagementModel as Model } from '../model/tenant-management.model';

const basePath = '/tenant';

async function getTenantManagementList(status: string) {
  const queryParams = [];

  if (status !== undefined && status !== '') {
    queryParams.push(`IsActive=${status}`);
  }

  const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';
  return await http.get<Model[]>(`${basePath}${queryString}`);
}

async function getTenantById(id: any) {
  return await http.get<Model>(`${basePath}/${id}`);
}

async function getTenantManagementListFiltering(page: number = 0, limit: number = 10, query: string) {
  var queryFilter = `_page=${page}&_limit=${limit}`;
  if (query !== '') {
    queryFilter += `&${query}`;
  }

  return await http.get<Model[]>(`${basePath}?${queryFilter}`);
}

async function postTenant(data: Model) {
  return await http.post<Model>(`${basePath}`, data);
}

async function putTenant(data: Model) {
  return await http.put<Model>(`${basePath}/${data.id}`, data);
}

async function deleteTenantById(id: any) {
  return await http.del(`${basePath}/${id}`);
}

export const TenantManagementService = {
  getTenantById,
  getTenantManagementList,
  getTenantManagementListFiltering,
  postTenant,
  putTenant,
  deleteTenantById,
};
