import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface Props {
  hideBreadcrumbs?: boolean;
  hideTitle?: boolean;
}

export function BreadcrumbsComponent(props: Props) {
  const { t } = useTranslation();

  const routes = [
    { path: '/', breadcrumb: `${t('menu.home')}` },
    { path: '/security', breadcrumb: `${t('menu.security')}` },
    { path: '/security/tenantmanagement', breadcrumb: `${t('menu.tenantManagement.title')}` },
    { path: '/security/tenantmanagement/add', breadcrumb: `${t('menu.tenantManagement.add')}` },
    { path: '/security/tenantmanagement/:id', breadcrumb: `${t('menu.tenantManagement.edit')}` },
    { path: '/security/usermanagement', breadcrumb: `${t('menu.userManagement.title')}` },
    { path: '/security/usermanagement/add', breadcrumb: `${t('menu.userManagement.add')}` },
    { path: '/security/usermanagement/:id', breadcrumb: `${t('menu.userManagement.edit')}` },
    { path: '/security/groupmanagement', breadcrumb: `${t('menu.groupManagement.title')}` },
    { path: '/security/groupmanagement/add', breadcrumb: `${t('menu.groupManagement.add')}` },
    { path: '/security/groupmanagement/:id', breadcrumb: `${t('menu.groupManagement.edit')}` },
    { path: '/security/userprofile', breadcrumb: `${t('menu.userProfile')}` },
    { path: '/settings', breadcrumb: `${t('menu.generalSettings')}` },
    { path: '/settings/healthcheck', breadcrumb: `${t('menu.healthCheck')}` },
    { path: '/settings/generalsettings', breadcrumb: `${t('menu.generalSettings')}` },
    { path: '/settings/apiclients', breadcrumb: `${t('menu.apiClients')}` },
    { path: '/masterdata', breadcrumb: `${t('menu.masterData')}` },
    { path: '/masterdata/countries', breadcrumb: `${t('menu.countries.title')}` },
    { path: '/masterdata/countries/add', breadcrumb: `${t('menu.countries.add')}` },
    { path: '/masterdata/countries/:id', breadcrumb: `${t('menu.countries.edit')}` },
    { path: '/masterdata/sites', breadcrumb: `${t('menu.sites.title')}` },
    { path: '/masterdata/sites/add', breadcrumb: `${t('menu.sites.add')}` },
    { path: '/masterdata/sites/:id', breadcrumb: `${t('menu.sites.edit')}` },
    { path: '/masterdata/departaments', breadcrumb: `${t('menu.departaments.title')}` },
    { path: '/masterdata/departaments/add', breadcrumb: `${t('menu.departaments.add')}` },
    { path: '/masterdata/departaments/:id', breadcrumb: `${t('menu.departaments.edit')}` },
    { path: '/masterdata/robotType', breadcrumb: `${t('menu.robotType.menu')}` },
    { path: '/masterdata/robotType/add', breadcrumb: `${t('menu.robotType.add')}` },
    { path: '/masterdata/robotType/:id', breadcrumb: `${t('menu.robotType.edit')}` },
    { path: '/masterdata/operationsystem', breadcrumb: `${t('menu.operationsystem.title')}` },
    { path: '/masterdata/operationsystem/add', breadcrumb: `${t('menu.operationsystem.add')}` },
    { path: '/masterdata/operationsystem/:id', breadcrumb: `${t('menu.operationsystem.edit')}` },
    { path: '/masterdata/machineType', breadcrumb: `${t('menu.machineType.title')}` },
    { path: '/masterdata/machineType/add', breadcrumb: `${t('menu.machineType.add')}` },
    { path: '/masterdata/machineType/:id', breadcrumb: `${t('menu.machineType.edit')}` },
    { path: '/masterdata/technologies', breadcrumb: `${t('menu.technologies.title')}` },
    { path: '/masterdata/technologies/add', breadcrumb: `${t('menu.technologies.add')}` },
    { path: '/masterdata/technologies/:id', breadcrumb: `${t('menu.technologies.edit')}` },
    { path: '/processes', breadcrumb: `${t('menu.processes.title')}` },
    { path: '/processes/process', breadcrumb: `${t('menu.processes.menu')}` },
    { path: '/processes/process/add', breadcrumb: `${t('menu.processes.add')}` },
    { path: '/processes/process/:id', breadcrumb: `${t('menu.processes.edit')}` },
    { path: '/processes/schedule', breadcrumb: `${t('menu.schedule')}` },
    { path: '/processes/events', breadcrumb: `${t('menu.events')}` },
    { path: '/processes/robotExecutions', breadcrumb: `${t('menu.robotExecutions')}` },
    { path: '/infrastructure', breadcrumb: `${t('menu.infrastructure')}` },
    { path: '/infrastructure/machine', breadcrumb: `${t('menu.machine.title')}` },
    { path: '/infrastructure/machine/add', breadcrumb: `${t('menu.machine.add')}` },
    { path: '/infrastructure/machine/:id', breadcrumb: `${t('menu.machine.edit')}` },
    { path: '/infrastructure/machineActivity', breadcrumb: `${t('menu.machineActivity')}` },
  ];

  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <>
      <div className="d-flex align-items-center flex-wrap mb-lg-0">
        {!props.hideTitle && (
          <h1 className="d-flex align-items-center text-dark fw-boldest my-1 fs-3">
            {breadcrumbs[breadcrumbs.length - 1].breadcrumb}
          </h1>
        )}
        {!props.hideBreadcrumbs && (
          <>
            <span className="h-20px border-gray-200 border-start mx-3"></span>
            <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
              {breadcrumbs
                .map(({ match, index, breadcrumb }: any) => (
                  <li key={Math.random()} className="breadcrumb-item text-muted">
                    <Link className="text-muted text-hover-primary me-2" key={Math.random()} to={match.pathname}>
                      {breadcrumb}
                    </Link>
                    <span className="bullet bg-gray-400 w-5px h-2px me-1"></span>
                  </li>
                ))
                .slice(0, breadcrumbs.length - 1)}
              <li className="breadcrumb-item text-dark">{breadcrumbs[breadcrumbs.length - 1].breadcrumb}</li>
            </ul>
          </>
        )}
      </div>
    </>
  );
}
