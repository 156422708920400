import { tableHelper } from 'common/helpers/table/table-helper';
import { itLabTableStyle } from 'common/helpers/table/table-style';
import SaveButton from 'common/partials/SaveButton';
import i18next from 'i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import en from '../i18n/en-us.json';
import es from '../i18n/es-es.json';
import pt from '../i18n/pt-br.json';
import { UserSelectorModel as Model } from '../model/user-selector.model';
import { UserSelectorService as Service } from '../services/user-selector.service';

export function UserSelectorComponent(props: { tenantId: number }) {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);

  const { id } = useParams();
  const { t } = useTranslation();
  const [tenantLeftData, setUserLeftData] = useState<Model[]>([]);
  const [tenantRightData, setUserRightData] = useState<Model[]>([]);
  const [selectedLeftRows, setSelectedLeftRows] = useState<Model[]>([]);
  const [selectedRightRows, setSelectedRightRows] = useState<Model[]>([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setToggleCleared(false);
  }, [toggleCleared]);
  useEffect(() => {
    fetchInitialData();
  }, []);

  function fetchInitialData() {
    (async () => {
      try {
        setToggleCleared(true);

        const userAndUsers = await Service.getTenantAndUsers(id);
        if (userAndUsers && !userAndUsers.hasErrors) {
          const availableUsers = userAndUsers.data.tenants.filter((tenantId) => !tenantId.inUser);
          const selectedUsers = userAndUsers.data.tenants.filter((tenantId) => tenantId.inUser);

          setUserLeftData(availableUsers);
          setUserRightData(selectedUsers);
        }
      } catch (err: any) {
        toast.error(err.response.data.ErrorCode);
      } finally {
      }
    })();
  }

  const handleLeftRowSelected = useCallback((state: any) => {
    setSelectedLeftRows(state.selectedRows as Model[]);
  }, []);

  const handleLeftRowClicked = useCallback(() => {}, []);

  const handleRightRowSelected = useCallback((state: any) => {
    setSelectedRightRows(state.selectedRows);
  }, []);

  const handleRightRowClicked = useCallback(() => {}, []);

  function clearSelected() {
    setSelectedRightRows([]);
    setSelectedLeftRows([]);
  }

  function handleAddButton() {
    const array = getDifference(tenantLeftData, selectedLeftRows);
    setUserLeftData(array);
    setUserRightData((previous) => [...previous, ...selectedLeftRows]);
    clearSelected();
    setToggleCleared(true);
  }

  function handleRemoveButton() {
    const a1 = getDifference(tenantRightData, selectedRightRows);
    setUserRightData(a1);
    setUserLeftData((previous) => [...previous, ...selectedRightRows]);
    clearSelected();
    setToggleCleared(true);
  }

  function getDifference(array1: Model[], array2: Model[]) {
    return array1.filter((object1) => {
      return !array2.some((object2) => {
        return object1.id === object2.id;
      });
    });
  }

  function handleSaveButtonClick() {
    (async () => {
      try {
        setIsLoading(true);

        let users: number[] = [];
        tenantRightData.forEach((element) => {
          users.push(element.id);
        });

        await Service.putUsers(id, users);

        toast.success(`${t('crud.update.successMessage')}`);
      } catch (err: any) {
        toast.error(err.response.data.ErrorCode);
      } finally {
        setIsLoading(false);
      }
    })();
  }

  const columns: TableColumn<Model>[] = [
    {
      name: t('generalMessages.selectAll'),
      selector: (row) => row.name,
      sortable: true,
      ignoreRowClick: true,
    },
  ];

  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-5">
            <div className="card w-100 shadow-none">
              <div className="pt-0">
                <DataTable
                  title={<h4 className="mb-10">{t('profileSelector.selectableItems')}</h4>}
                  className="mh-500px overflow-auto"
                  columns={columns}
                  data={tenantLeftData}
                  noDataComponent={tableHelper.getNoDataComponent()}
                  contextMessage={tableHelper.getContextMessage()}
                  selectableRows
                  highlightOnHover
                  striped
                  dense
                  onSelectedRowsChange={handleLeftRowSelected}
                  onRowClicked={handleLeftRowClicked}
                  clearSelectedRows={toggleCleared}
                  customStyles={itLabTableStyle}
                />
              </div>
            </div>
          </div>
          <div className="col-2 text-center pt-20">
            <div className="row">
              <div className="col-12">&nbsp;</div>
            </div>
            <div className="row">
              <div className="col-12">
                <button type="button" className="btn btn-primary" name="addButton" onClick={handleAddButton}>
                  {'>>'}
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <button
                  type="button"
                  className="btn btn-light btn-active-light-primary mt-2"
                  onClick={handleRemoveButton}
                >
                  {'<<'}
                </button>
              </div>
            </div>
          </div>
          <div className="col-5">
            <div className="card w-100 py-4 shadow-none">
              <div className="pt-0">
                <DataTable
                  title={<h4 className="mb-10">{t('profileSelector.selectedItems')}</h4>}
                  className="mh-500px overflow-auto"
                  columns={columns}
                  data={tenantRightData}
                  noDataComponent={tableHelper.getNoDataComponent()}
                  contextMessage={tableHelper.getContextMessage()}
                  selectableRows
                  highlightOnHover
                  striped
                  dense
                  onSelectedRowsChange={handleRightRowSelected}
                  onRowClicked={handleRightRowClicked}
                  clearSelectedRows={toggleCleared}
                  customStyles={itLabTableStyle}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card-footer d-flex justify-content-end py-6 px-9">
        <SaveButton type="button" loading={isLoading} onClick={handleSaveButtonClick} />
      </div>
    </div>
  );
}
