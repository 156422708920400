import clsx from 'clsx';
import i18next from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import en from 'i18n/en-us.json';
import pt from 'i18n/pt-br.json';
import es from 'i18n/es-es.json';
import { useLoading } from 'common/loading/loading.hook';
import { useAvatar } from 'common/helpers/avatar/context/avatar.hook';
import { HeaderUserMenu } from './childs/HeaderUserMenu';
import { TopbarService as Service } from './services/Topbar.service';
import { TokenHelper } from 'common/helpers/auth/token-helper';
import { ITLabAvatar } from 'common/helpers/avatar/itlab-avatar';
import TokenExpirationCountdown from './childs/TokenExpirationCountdown';
import { AlertsIcon } from 'common/helpers/alerts/alerts-icon';
import { TenantSelector } from './childs/TenantSelector';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px';

export function Topbar() {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);

  const { isLoading } = useLoading();
  const { avatar } = useAvatar();
  const [avatarBuffer, setAvatarBuffer] = useState<string>('');

  const fetchData = () => {
    (async () => {
      try {
        isLoading(true);
        const response: any = await Service.getUserAvatar();
        if (response.data.byteLength > 0) {
          setAvatarBuffer(response.data);
        }
      } catch (err) {
        // toast.error(`${t('crud.read.errorMessage')}`);
      } finally {
        isLoading(false);
      }
    })();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatar]);

  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <TokenExpirationCountdown />
      </div>

      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <AlertsIcon />
      </div>

      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id="kt_header_tenant_menu_toggle"
      >
        <TenantSelector iconWrapperClass={toolbarUserAvatarHeightClass} />
      </div>
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)} id="kt_header_user_menu_toggle">
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          <ITLabAvatar buffer={avatarBuffer} fullName={new TokenHelper().GetName()} />
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  );
}
