import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { NotificationModel} from 'common/helpers/alerts/alerts.model';
import { NotificationItem } from './notifications/NotificationItem';
import i18next from 'i18next'
import en from '../../i18n/en-us.json';
import pt from '../../i18n/pt-br.json';
import es from '../../i18n/es-es.json';
import { NotificationService } from '../../../../app/notifications/services/notification.service'; // Importe o serviço de notificação

export function PinnedNotifications() {
  const initialData: NotificationModel[] = [];

  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);
  const { t } = useTranslation();
  const [pinnedNotifications, setPinnedNotifications] = useState<NotificationModel[]>(initialData);

  const fetchPinnedNotifications = async () => {
    try {
      const response = await NotificationService.getNotificationPinned();
      if (response && response.data) {
        setPinnedNotifications(response.data as NotificationModel[]);
      } else {
        setPinnedNotifications(initialData);
      } 
    } catch (error) {
      console.error(error);
    }

  }

  useEffect(() => {
    const receiveMessages = async () => {
      await fetchPinnedNotifications();
    }

    receiveMessages();
  }, []);

  const pinnedNotificationsCount = pinnedNotifications.length;

  return (
    <>
      {pinnedNotificationsCount > 0 ? 
      (
        <div className="card">
          <div className="card-body">
            <div className="alert m-0 p-0" role="alert">
              <h4 className="alert-heading">{`${t("notifications.title")}`}</h4>
              <ol className="list-group list-group-numbered  list-group-flush" style={{
                "maxHeight": "100px",
                "overflow": "auto"
                }}>
                {
                  pinnedNotifications
                    .map((n: NotificationModel) => <NotificationItem notification={n} key={n.id} />)
                }
              </ol>
              <p className="text-body-tertiary text-end mt-1">
                {
                `${t("notifications.listInfo", { "notificationCount": pinnedNotificationsCount })}`
                }
              </p>
            </div>
          </div>
        </div>
      ) :
      (<></>)
      }
    </>
  );
}